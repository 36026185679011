<template>
  <div>
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "Protocol",
  data() {
    return {
      content: ""
    };
  },
  computed: {},
  watch: {},
  async created() {
    let res = await this.$axios.request({
      url: "/api/Publics/get_xieyi",
      params: {
        type: this.$route.query.type || 1
      }
    });
    console.log(res);
    this.content = res.data.data.content;
    document.title = res.data.data.name || "协议";
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px;
}
</style>
